<template>
  <div class="news-add" v-if="!isProcessing">
    <news-form />
  </div>
</template>

<script>
import NewsForm from '@/components/news/form.vue'

export default {
  name: 'news-add',
  components: { NewsForm },
  mounted () {
    // 処理中を解除
    this.$store.commit('setProcessing', false)
  },
  computed: {
    /**
     * @return {Boolean} 処理中かどうか
     */
    isProcessing () {
      return this.$store.getters.isProcessing
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/size.scss';

.news-add {
  padding: $padding_height $padding_width;
  margin-top: $header_height;
}
</style>
